import React from 'react';
import './Contact.scss';

import { connect } from 'react-redux';

import { ScreenWithNavBarAndFooter } from 'app/components';

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
}

const Contact = connect(mapStateToProps, actionCreators)(props => {

    const { lang, locale_string } = props.pageInfo;
    
    return (
        <ScreenWithNavBarAndFooter>
            <section className="page-section contact-section">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="sec-header">
                                <p className="sec-description fw-bold text-primary">CONTACT US</p>
                                <h2 className="sec-title mb-4">Get in touch today</h2>
                                <p className="sec-description text-primary">Please use the form below to contact us for any inquiries or feedback. We'll get back to you as soon as possible.</p>
                            </div>
                            <div className="sec-content">
                                <ul>
                                    <li className="fa-envelope">contact@3bgroups.com</li>
                                    <li className="fa-location-dot">225 Wilmington West Chester Pike, Suite 200, <br className="desktop" />Chadds Ford, PA 19317, <br className="desktop" />United States of America</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid w-100" src="/public_assets/images/contact-img.jpg" alt=""></img>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

export {
    Contact
};
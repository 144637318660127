import { NAVIGATION } from './routes';

const BASE_API = 'https://find.locamos.vn/api'

const HOMEPAGE = 'https://globe.locamos.vn/'

const GG_MAPS_KEY = ''

export {
    BASE_API,
    HOMEPAGE,
    NAVIGATION,
    GG_MAPS_KEY
}
export const NAVIGATION = {
    // Authentications
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    LOGOUT: '/logout',
    
    // Main Screens
    DASHBOARD: '/',
    ACCOUNT: '/account',

    // Others
    ERROR_404: '/not-found',
    TERMS_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    PAYMENT_POLICY: '/payment-policy',
    WEBSITE_OWNER: '/ordering-policy',
    CONTACT_US: '/contact-us',
    ABOUT_US: '/about-us',
    FAQS: '/faqs',
    KEYWORDS: '/keywords'
}
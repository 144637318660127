import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { pageInfoActions, authActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';

import { ScreenWithNavBarAndFooter, Slider, MarkerCluster } from 'app/components';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper';

import {
    MapContainer,
    TileLayer,
	useMap,
	Marker,
	Popup,
	useMapEvent,
} from 'react-leaflet';

import * as L from "leaflet";

import { homepageService } from 'services';

import { Modal } from 'bootstrap';

const store_list = [
    {
        id: 1,
        name: 'LocaCafe Store #1',
        position: '154-156 Nguyễn Hữu Thọ, Phước Kiển, Nhà Bè, Thành phố Hồ Chí Minh',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 10.714957704016596,
        long: 106.70440015406216
    },
    {
        id: 2,
        name: 'LocaCafe Store #2',
        position: '24 Nguyễn Công Hoan, Giảng Võ, Ba Đình, Hà Nội.',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.027429025919318,
        long: 105.81374844097691
    },
    {
        id: 3,
        name: 'LocaCafe Store #3',
        position: 'Số 23 Sunrise E, KĐT The Manor Central Park, Đại Kim, Hoàng Mai, Hà Nội (Công viên Trung tâm The Manor Central Park Nguyễn Xiển)',
        img: '/public_assets/images/home/store-4.jpg',
        lat: 20.97145208430682,
        long: 105.812462210688
    },
    {
        id: 4,
        name: 'LocaCafe Store #4',
        position: 'Khu nhà ở thấp tầng TT3 lô số 32, Khu đô thị mới Thành Phố Giao Lưu, Từ Liêm, Hà Nội ',
        img: '/public_assets/images/home/store-1.jpg',
        lat: 21.05183988974107,
        long: 105.77705299636264
    },
    {
        id: 5,
        name: 'LocaCafe Store #5',
        position: '4/3c Khu Phố 2, Phường Tân Phong, Thành Phố Biên Hoà, Đồng Nai',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 10.960615051708409,
        long: 106.8527221647512
    },
    {
        id: 6,
        name: 'LocaCafe Store #6',
        position: 'Khu Nhà Mới , Cấn Hữu, Quốc Oai, Hà Nội',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.0415807,
        long: 105.7426738
    },
    {
        id: 7,
        name: 'LocaCafe Store #7',
        position: '02 Lô A15 Khu đô thị mới hiện đại phía đông Hòn Cặp Bè, phường Hồng Hải, Thành phố Hạ Long, tỉnh Quảng Ninh',
        img: '/public_assets/images/home/store-4.jpg',
        lat: 20.950375323172768,
        long: 107.09205418987145
    },
    {
        id: 8,
        name: 'LocaCafe Store #8',
        position: '301 Quốc Lộ 91b, Phường Long Tuyền, Bình Thuỷ, Cần Thơ',
        img: '/public_assets/images/home/store-1.jpg',
        lat: 10.03745721373833,
        long: 105.7434612867572
    },
    {
        id: 9,
        name: 'LocaCafe Store #9',
        position: '77 Nguyễn Hoàng, phường Kim Long, tp Huế',
        img: '/public_assets/images/home/store-2.jpg',
        lat: 16.46344417040173,
        long: 107.55464313753701
    },
    {
        id: 10,
        name: 'LocaCafe Store #10',
        position: 'Khu đấu giá Phương Canh ĐG1, Trịnh Văn Bô, Nam Từ Liêm',
        img: '/public_assets/images/home/store-3.jpg',
        lat: 21.03643055137933,
        long: 105.73614921009765
    }
]

const MapCenterUpdater = ({ position, zoom }) => {
	const map = useMap();
	useEffect(() => {
		map.setView(position, zoom);
	}, [map, position, zoom])	
}

const NFTMaps = ({ lang, country, alertError, alertSuccess }) => {
	const mapDefaultCenterPos = [21.02274,105.836964];

	const [loading, setLoading] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [mapType, setMapType] = useState('m');
	const [mapCenterPos, setMapCenterPos] = useState({ lat: mapDefaultCenterPos[0], lng: mapDefaultCenterPos[1] });
	const [mapZoomLevel, setMapZoomLevel] = useState(6);
	const [mapSearchString, setMapSearchString] = useState('');
	const [mapSearchPrevString, setMapSearchPrevString] = useState('');
	const [mapSearchResult, setMapSearchResult] = useState([]);
	const [mapSearchResultShow, setMapSearchResultShow] = useState(false);

	useEffect(() => {
		if (country) {
			setLoading(false);
			setMapCenterPos(country.position);

            setMarkers(store_list)

			// homepageService.fetch_list_place(country.position.lat+','+country.position.lng, country.short_name)
			// .then(data => {
			// 	if (data) {
			// 		// console.log(data);
			// 		setMarkers(data);
			// 	} else {
			// 		setMarkers([]);
			// 	}
			// 	setLoading(false);
			// })
		}

		return () => {
			setMarkers([]);
		}
	}, [country, setLoading, setMarkers]);	

	function handleMapTypeChange(e) {
		let map_type = e.target.value;
		setMapType(map_type);
	}

	// function handleMapSearchStringInput(e) {
	// 	setMapSearchString(e.target.value);
	// 	setMapSearchResultShow(false);
	// }

	// function handleMapSearchEnter(e) {
	// 	if(e.keyCode === 13) {
	// 		submitSearch();
	// 	}
	// }

	// function submitSearch() {
	// 	console.log('Search: ' + mapSearchString);
	// 	if (mapSearchString !== mapSearchPrevString) {
			
	// 		// use Google Maps Javascript API
	// 		geocoder.geocode({ address: mapSearchString }, function (results, status) {
	// 			if (status == 'OK') {
	// 				setMapSearchPrevString(mapSearchString);
	// 				setMapSearchResult(results);
	// 				setMapSearchResultShow(true);
	// 			} else {
	// 				setMapSearchResult([]);
	// 				console.log('Geocode was not successful for the following reason: ' + status);
	// 				alertError(text.dashboard.map_search_result_empty[lang]);
	// 			}
	// 		});

	// 		// homepageService.search_place(mapSearchString)
	// 		// .then(data => {
	// 		// 	if (data) {
	// 		// 		setMapSearchPrevString(mapSearchString);
	// 		// 		setMapSearchResult(data);
	// 		// 		setMapSearchResultShow(true);
	// 		// 	} else {
	// 		// 		setMapSearchResult([]);
	// 		// 		alertError(text.dashboard.map_search_result_empty[lang]);
	// 		// 	}
	// 		// });
	// 	} else {
	// 		setMapSearchResultShow(true);
	// 	}
	// }

	// function renderMapSearchResults(list) {
	// 	if (!list || list.length === 0) {
	// 		return null;
	// 	}
	// 	// console.log(list);

	// 	function zoomMapToPos(lat, lng, address) {
	// 		setMapCenterPos([lat, lng]);
	// 		setMapZoomLevel(15);
	// 		setMarkerPickerDefaultPos({lat, lng, address });
	// 		setMapSearchResultShow(false);
	// 	}

	// 	let res = [];
	// 	list.forEach((item, index) => {
	// 		res.push(
	// 			<div key={'mapSearchRes_'+index} className="map-search-result-item" onClick={() => { zoomMapToPos(item.geometry.location.lat(), item.geometry.location.lng(), item.formatted_address) }}>
	// 				{item.formatted_address} ({item.geometry.location.lat()}, {item.geometry.location.lng()})
	// 			</div>
	// 		)
	// 	})

	// 	return <>{res}</>;
	// }

	return (
		<div id="map">
			<div className={`map-loading-indicator ${ loading ? 'show' : '' }`}>
				Loading map data... <i className="far fa-cog fa-spin"></i>
			</div>
			{
				country && !loading
				? <button className="btn btn-sm btn-light bg-white btn-select-country-on-maps" data-bs-toggle="modal" data-bs-target="#selectCountryModal">
					<span className="select-country-flag" style={{ backgroundImage: 'url("' + country.flag_image + '")' }}></span>
					<span className="select-country-label">{country.display_name}</span>
				</button>
				: <></>
			}
			{/* <div className="map-search-box">
				<div className="input-group">
					<input type="text" className="form-control form-control-lg bg-white" id="mapSearchInput" placeholder={text.dashboard.map_search_input_placeholder[lang]} onChange={handleMapSearchStringInput} onKeyDown={handleMapSearchEnter}></input>
					<button className="btn btn-lg btn-primary btn-icon" type="button" id="button-addon2" onClick={submitSearch}><i className="far fa-search"></i></button>
				</div>
				<div className={`map-search-results ${ mapSearchResultShow ? 'show' : '' }`}>
					{ renderMapSearchResults(mapSearchResult) }
				</div>
			</div> */}
			<div className="map-style-selector">
				<div className="form-check form-check-inline">
					<input className="form-check-input d-none" type="radio" name="mapTypeSelector" id="mapTypeStreet" value="m" defaultChecked="true" onChange={handleMapTypeChange}></input>
					<label className="form-check-label" htmlFor="mapTypeStreet">
						<img src="public_assets/images/map-type-street.png" alt="" className="img-fluid map-style-img"></img>
						<span className="map-style-label">{text.dashboard.map_type_street[lang]}</span>
					</label>
				</div>
				<div className="form-check form-check-inline">
					<input className="form-check-input d-none" type="radio" name="mapTypeSelector" id="mapTypeSatelite" value="s,h" onChange={handleMapTypeChange}></input>
					<label className="form-check-label" htmlFor="mapTypeSatelite">
						<img src="public_assets/images/map-type-satelite.png" alt="" className="img-fluid map-style-img"></img>
						<span className="map-style-label text-white">{text.dashboard.map_type_satelite[lang]}</span>
					</label>
				</div>
			</div>
			<MapContainer className="nft-maps" center={mapDefaultCenterPos} zoom={13} scrollWheelZoom={true} zoomControl={false} attributionControl={false} preferCanvas={true}>
				<TileLayer
					attribution=''
					// url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					// url="https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?apiKey=Bt771_BaSy5e0qyE7qowpIcNoiJ9lIqgk3zfmkJdso4"
					url={`https://{s}.google.com/vt/lyrs=${mapType}&x={x}&y={y}&z={z}`}
					subdomains={['mt0','mt1','mt2','mt3']}
				/>
				<MarkerCluster markers={markers} />
				<MapCenterUpdater position={mapCenterPos} zoom={mapZoomLevel} />
			</MapContainer>
		</div>
	)
}

const Dashboard = props => {
    const { history, location, pageInfo, logOut } = props;
    const lang = pageInfo.display_lang;

    const [storeCard, setStoreCard] = useState(store_list[0]);

    const menu_items = [
        {
            img_url: '/public_assets/images/home/menu-1.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-2.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-3.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-4.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-5.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-6.jpg'
        },
        {
            img_url: '/public_assets/images/home/menu-7.jpg'
        },
    ]

    function renderMenuItem(item, index) {
        return (
            <div className="menu-slide-item" key={'menu_'+index}>
                <div className="menu-item-img-height">
                    <div className="ratio ratio-1x1">
                        <div className="menu-item-img" style={{ backgroundImage: 'url("' + item.img_url + '")' }}></div>
                    </div>
                </div>
            </div>
        )
    }

    function renderStoreList(list) {
        if (!list || list.length === 0) return;

        let team_members = [];
        list.map((item, index) => {
            team_members.push(
                <div className={`store-item ${ storeCard.id && storeCard.id === item.id ? 'active' : '' }`} key={'store_item_' + index} onClick={() => { setStoreCard(item) }}>
                    <div className="store-item-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="store-item-detail">
                        <h5>{item.name}</h5>
                        <div className="store-location">
                            <i className="fa-light fa-map-marker-alt fa-2x me-3"></i>
                            <p>{item.position}</p>
                        </div>
                    </div>
                </div>
            )
            return item;
        });

        return team_members;
    }

    function renderStoreListSlideItem(item, index) {
        return (
            <div className="store-card-wrapper" key={'store_item_' + index}>
                <div className="store-card">
                    <div className="store-card-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="store-card-detail">
                        <h5>{item.name}</h5>
                        <div className="store-location">
                            <i className="fa-light fa-map-marker-alt fa-2x me-3"></i>
                            <p>{item.position}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderStoreCard(item) {
        if (!item) return;

        return (
            <div className="store-card-wrapper">
                <div className="store-card">
                    <div className="store-card-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="store-card-detail">
                        <h5>{item.name}</h5>
                        <div className="store-location">
                            <i className="fa-light fa-map-marker-alt fa-2x me-3"></i>
                            <p>{item.position}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    const [countryList, setCountryList] = useState([
		{
			short_name: 'VN',
			long_name: 'Vietnam',
			display_name: 'Việt Nam (Vietnam)',
			flag_image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/255px-Flag_of_Vietnam.svg.png',
			position: { lat: '16.00469606448982', lng: '107.7798463973814' }
		},
		// {
		// 	short_name: 'TH',
		// 	long_name: 'Thailand',
		// 	display_name: 'ประเทศไทย (Thailand)',
		// 	flag_image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/255px-Flag_of_Thailand.svg.png',
		// 	position: { lat: '13.7563309', lng: '100.5017651' }
		// }
	])
	const [country, setCountry] = useState(null);
	const [center, setCenter] = useState(null);

	useEffect(() => {
		if (!country) {
			if (countryList.length === 1) {
				setCountry(countryList[0]);
				setCenter(countryList[0].position);
			} else {
				let selectCountryModal = new Modal('#selectCountryModal');
				selectCountryModal.show();
			}
		}

		return () => {
			// setCountry(null);
		}
	}, [country])

	function renderCountryList(list) {
		if (!list || list.length === 0) return null;

		function handleSelectCountry(country) {
			// let selectCountryModal = new Modal('#selectCountryModal');
			// selectCountryModal.hide('hide');
			document.getElementsByClassName('modal-backdrop')[0].remove();
			setCountry(country);
			setCenter(country.position);
		}

		let countries = [];
		list.forEach((item, index) => {
			countries.push(
				<div className="select-country-item" key={'selCountry'+index}>
					<button className="btn btn-outline-secondary" onClick={() => { handleSelectCountry(item) }} data-bs-dismiss="modal">
						<span className="select-country-flag" style={{ backgroundImage: 'url("' + item.flag_image + '")' }}></span>
						<span className="select-country-label">{item.display_name}</span>
					</button>
				</div>
			)
		})

		return countries;
	}

    return (
        <ScreenWithNavBarAndFooter>
            <section id="welcome" className="page-section welcome-section">
                <div className="header-bg">
                    <div className="row h-100">
                        <div className="col-md-5 h-100 p-0 desktop">
                            <div className="header-col-left h-100"></div>
                        </div>
                        <div className="col-md-7 h-100 p-0">
                            <div className="header-col-left"></div>
                            <div className="header-col-right h-100" style={{ backgroundImage: 'url(/public_assets/images/home/header-bg.jpg)' }}></div>
                        </div>
                    </div>
                </div>
                <div className="container-xl header-content">
                    <div className="row align-items-center">
                        <div className="col-md-4 pe-md-0">
                            <h1 className="welcome-title">Chào mừng bạn tới LocaCafe!</h1>
                            <p className="welcome-description mb-5">Nơi kết hợp văn hóa cà phê với những trải nghiệm công nghệ thú vị. Đắm mình trong không gian hiện đại, thưởng thức các loại đồ uống độc đáo chỉ với 29k/ly. Hãy cùng chúng tôi khám phá một hành trình mới với Cà phê và Công nghệ!</p>
                            <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                    <a href="#store" className="btn btn-light w-100 h-100">Cửa hàng</a>
                                </div>
                                <div className="col-md-6">
                                    <a href="#contact" className="btn btn-outline-light w-100 h-100">Khám phá</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="story" className="page-section story-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Câu chuyện LocaCafe</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-md-8">
                                        <p className="mb-5 text-justify">
                                            Chúng tôi là LocaCafe - nơi sáng tạo và kết nối. Với niềm đam mê cà phê và tầm nhìn Quốc tế, chúng tôi mang đến một không gian độc đáo, kết hợp hương vị cà phê thơm ngon và sức mạnh công nghệ đột phá.<br />
                                            <br />
                                            LocaCafe không chỉ đơn thuần là quán cà phê - chúng tôi là một hành trình kết nối, khám phá và trải nghiệm. Với không gian công nghệ đẳng cấp, từ VR/AR đến ứng dụng LocaMos, bạn sẽ khám phá một thế giới đầy màu sắc tương lai.
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <img className="img-fluid w-100 mb-5 mb-md-0" src="/public_assets/images/home/story-1.jpg" alt=""></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-12 order-2 order-md-1">
                                        <img className="img-fluid w-100 mb-5" src="/public_assets/images/home/story-2.jpg" alt=""></img>
                                    </div>
                                    <div className="col-md-8 order-1 order-md-2">
                                        <p className="mb-5 mb-md-0">
                                            LocaCafe còn là một bức tranh về văn hóa và tinh thần của người Việt Nam, với những đặc sản địa phương và kết nối xuyên biên giới. Chúng tôi trân trọng văn hóa cà phê Việt Nam và chú tâm tạo ra những hương vị độc đáo từ hạt cafe Robusta thuần Việt. Menu đa dạng của chúng tôi thể hiện sự kết hợp giữa truyền thống và đổi mới, phục vụ cả người Việt và bạn bè Quốc tế. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="menu" className="page-section menu-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Menu</h2>
                        <p className="sec-description">Thưởng thức những hương vị tươi mới đồng giá chỉ 29k/ly. Hãy cùng chúng tôi khám phá những thức uống độc đáo, kết hợp giữa truyền thông và hiện đại.</p>
                        <div className="text-center">
                            <a href="#store" target="_blank" className="btn btn-primary btn-190 me-md-3 mb-3 mb-md-0">Cửa hàng</a>
                            <a href="#contact" target="_blank" className="btn btn-outline-primary btn-190">Liên hệ</a>
                        </div>
                    </div>
                    <div className="sec-content">
                        <div className="menu-slider">
                            <Slider
                                list={menu_items}
                                renderItem={renderMenuItem}
                                overrideConfig={{
                                    // pagination={{
                                    //     clickable: true,
                                    // }},
                                    modules: [Autoplay],
                                    slidesPerView: 1,
                                    breakpoints: {
                                        768: {
                                            slidesPerView: 5,
                                            effect: 'fade'
                                        }
                                    },
                                    centeredSlides: true,
                                    centerInsufficientSlides: true,
                                    initialSlide: 3,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="experience" className="page-section experience-section">
                <div className="container-xl">
                    <div className="experience-section-wrapper">
                        <div className="sec-header text-center">
                            <h2 className="sec-title">Trải nghiệm Công nghệ</h2>
                            <p className="sec-description">Chúng tôi tự hào là điểm đến hấp dẫn cho những người yêu thích công nghệ và đang tìm kiếm một sự kết hợp tuyệt vời giữa cà phê và thế giới số.</p>
                            <div className="text-center">
                                <a href="#store" target="_blank" className="btn btn-primary btn-190 me-md-3 mb-3 mb-md-0">Cửa hàng</a>
                                <a href="#contact" target="_blank" className="btn btn-outline-primary btn-190">Liên hệ</a>
                            </div>
                        </div>
                        <div className="sec-content">
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <div className="experience-item">
                                        <img src="/public_assets/images/home/experience-icon-1.png" alt="" width={72} height={72}></img>
                                        VR/AR <br className="desktop" />đỉnh cao
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="experience-item">
                                        <img src="/public_assets/images/home/experience-icon-2.png" alt="" width={72} height={72}></img>
                                        Săn quà với LocaGift
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="experience-item">
                                        <img src="/public_assets/images/home/experience-icon-3.png" alt="" width={72} height={72}></img>
                                        Tích điểm với LocaBiz
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="experience-item">
                                        <img src="/public_assets/images/home/experience-icon-4.png" alt="" width={72} height={72}></img>
                                        Kết nối với LocaMeet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="store" className="page-section store-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Thả mình vào không gian kết nối cà phê & công nghệ tại LocaCafe</h2>
                        <p className="sec-description">Thưởng thức những hương vị tươi mới đồng giá chỉ 29k/ly. Hãy cùng chúng tôi khám phá những thức uống độc đáo, kết hợp giữa truyền thông và hiện đại.</p>
                        <div className="text-center">
                            <a href="#contact" target="_blank" className="btn btn-lg btn-primary btn-190">Liên hệ</a>
                        </div>
                    </div>
                    <div className="sec-content">
                        <div className="row desktop">
                            <div className="col-md-6 mb-5 mb-md-0">
                                { renderStoreCard(storeCard) }
                            </div>
                            <div className="col-md-6">
                                <div className="store-list">
                                    { renderStoreList(store_list) }
                                </div>
                            </div>
                        </div>
                        <div className="store-list-slider mobile">
                            <Slider
                                list={store_list}
                                renderItem={renderStoreListSlideItem}
                                overrideConfig={{
                                    modules: [Autoplay],
                                    slidesPerView: 1,
                                    centeredSlides: true,
                                    centerInsufficientSlides: true,
                                    autoplay: {
                                        delay: 2500,
                                        disableOnInteraction: true
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="maps" className="page-section maps-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <h2 className="sec-title">Khám phá LocaCafe gần bạn ngay!</h2>
                        <p className="sec-description">Trải nghiệm hành trình độc đáo của Cà phê và Công nghệ chỉ với 29k/ly</p>
                    </div>
                    <div className="sec-content">
                        <NFTMaps lang={lang} country={country} center={center} />

                        <div className="modal fade" id="selectCountryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="selectCountryModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="selectCountryModalLabel">Chọn quốc gia</h1>
                                    </div>
                                    <div className="modal-body">
                                        <div className="select-country-list">
                                            {renderCountryList(countryList)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
}

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
    logOut: authActions.logout
}

export default connect(mapStateToProps, actionCreators)(Dashboard);
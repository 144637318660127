import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';

import { NAVIGATION } from 'config';
import PrivateRoute from "./PrivateRoute";

//Screen imports
import { 
    Dashboard,
    Login, Register, ForgotPassword, Logout,
    Account,
    Contact,
    TermsOfUse,
    PrivacyPolicy,
    ContactUs
} from 'app/screens';

const HashNavigation = props => {
    let page_url = useLocation();

    const [hash, setHash] = useState('');

    useEffect(() => {
        setHash(page_url.hash);

        return () => {
            setHash('');
        }
    }, [page_url.hash, setHash])

    useEffect(() => {
        if (hash !== '') {
            try {
                document.querySelector(hash).scrollIntoView();   
            } catch (error) {
                console.log(document.querySelector(hash), error);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [hash])
}

export default function AppNavigator() {
    return (
        <>
        <HashNavigation />
        <Routes>
            <Route exact path={NAVIGATION.DASHBOARD} element={<Dashboard />} />
            {/* <Route path={NAVIGATION.CONTACT_US} element={<Contact />} /> */}

            <Route path={NAVIGATION.TERMS_OF_USE} element={<TermsOfUse />} />
            <Route path={NAVIGATION.PRIVACY_POLICY} element={<PrivacyPolicy />} />

            {/* <Route path={NAVIGATION.LOGIN} element={<Login />} />
            <Route path={NAVIGATION.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={NAVIGATION.REGISTER} element={<Register />} />
            <Route path={NAVIGATION.LOGOUT} element={<Logout />} />

            <Route path={NAVIGATION.ACCOUNT} element={
                <PrivateRoute>
                    <Account />
                </PrivateRoute>
            } /> */}
            
            <Route index path="*" element={<NoMatch />} />
        </Routes>
        </>
    )
}

const NoMatch = props => {
    return <Navigate to={NAVIGATION.DASHBOARD} replace={true} />
}